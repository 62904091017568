<template>
  <div class="value_added_service">
    <headerNav pathname="增值服务" />
    <text-common
      title="增值服务"
      :img="img"
    />

    <div
      v-for="(item, index) in list"
      :key="index"
      class="value_added_service_conter"
    >
      <div
        class="conter_bg"
        :style="{ marginTop: item.marginTop, marginBottom: item.marginBottom }"
      >
        <img
          width="1200px"
          height="160px"
          :src="item.picUrl"
          alt=""
        />
        <div
          class="briefIntroduction"
          :style="{ top: item.top }"
        >
          {{ item.briefIntroduction }}
          <div style="margin-top: 10px">
            {{ item.briefIntroductionTwo }}
          </div>
          <div style="margin-top: 10px">
            {{ item.briefIntroductionThree }}
          </div>
        </div>
        <div class="title_bg">
          <img
            width="25px"
            height="30px"
            :src="item.serialNumber"
            alt=""
          />
          <span class="title">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <bottom-navigation />
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("../assets/valueAddedService/valueAddedService.png"),
      list: [
        {
          serialNumber: require("../assets/valueAddedService/01.png"),
          picUrl: require("../assets/valueAddedService/conter_bg01.jpg"),
          title: "【保价运输服务】",
          briefIntroduction: "以托运人申明货物的实际价值为基础，我司与客户共同确定的特殊增值服务，物流运输过程中，由于我司责任",
          briefIntroductionTwo: "导致货物损坏或遗失，则货物出险，客户可获得我司的相应赔偿。",
          marginTop: '135px',
          top: "55px",
        },
        {
          serialNumber: require("../assets/valueAddedService/02.png"),
          picUrl: require("../assets/valueAddedService/conter_bg02.jpg"),
          title: "【上门接货服务】",
          briefIntroduction: "根据客户实际需求，为客户办理公司到指定地点上门提取相关寄送物品的提货业务，让您足不出户即可发货。",
          marginTop: '120px',
          top: "70px",
        },
        {
          serialNumber: require("../assets/valueAddedService/03.png"),
          picUrl: require("../assets/valueAddedService/conter_bg03.jpg"),
          title: "【仓储管理服务】",
          briefIntroduction: "为客户的货物提供三天免费仓储，因客户的原因导致货物超过三天未收货或提货则需收取一定的保管费用。",
          marginTop: '120px',
          top: "70px",
        },
        {
          serialNumber: require("../assets/valueAddedService/04.png"),
          picUrl: require("../assets/valueAddedService/conter_bg04.jpg"),
          title: "【综合信息服务】",
          briefIntroduction: "实时跟踪，货物信息尽在掌控，为您提供实时的货物状态信息，自运单生成之时起，跟踪货物所在的中转环",
          briefIntroductionTwo: "节，为您发布，实时短信通知，并提供签收反馈服务。",
          marginTop: '120px',
        },
        {
          serialNumber: require("../assets/valueAddedService/05.png"),
          picUrl: require("../assets/valueAddedService/conter_bg05.jpg"),
          title: "【送货就位服务】",
          briefIntroduction: "根据客户需求，将货物送至客户指定的地方递交收件人并获得签收信息，直接保证快件快速、准确、安全地送",
          briefIntroductionTwo: "达客户的最后一环，也同长期客户建立起良好的关系。",
          marginTop: '120px',
        },
        {
          serialNumber: require("../assets/valueAddedService/06.png"),
          picUrl: require("../assets/valueAddedService/conter_bg06.jpg"),
          title: "【代收货款服务】",
          briefIntroduction: "线下交易的中每次物流的订货批量较大，而且买家和卖家距离的地方又比较远，买家无法做到每次上门验货后",
          briefIntroductionTwo: "再提走；因此我司可以接受委托代为收取货款，买方验货完毕以后将货款交给我司。",
          marginTop: '120px',
        },
        {
          serialNumber: require("../assets/valueAddedService/07.png"),
          picUrl: require("../assets/valueAddedService/conter_bg07.jpg"),
          title: "【等通知放货服务】",
          briefIntroduction: "物流双方进行货物交易，收货方货款没有结算/结清发货方相关费用，而要求先行发货。发货方委托物流公司把",
          briefIntroductionTwo: "货物发送到目的地之后，通知收货方提供有效证件查验货物，若没有疑问，由收货方把应付款(余款)支付给发货",
          briefIntroductionThree: "人，发货人通知物流公司，物流公司才可以把货物移交给收货人。",
          marginTop: '120px',
          top: "40px",
        },
        {
          serialNumber: require("../assets/valueAddedService/08.png"),
          picUrl: require("../assets/valueAddedService/conter_bg08.jpg"),
          title: "【签收回单服务】",
          briefIntroduction: "签收回单是发货客户在我司托运货物送至目标点，收货客户正常签收提货时，会出具一张几联的发货单，客户",
          briefIntroductionTwo: "在回单上面的备注记录当时货品接收的实际情况。",
          marginTop: '120px',
          marginBottom: "47px"
        },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.value_added_service {
  .value_added_service_conter {
    width: 100%;
    // font-size: 24px;
    color: #000;
    text-align: center;
    .title_bg {
      text-align: left;
      position: absolute;
      top: -45px;
      .title {
        display: inline-block;
        transform: translateY(-7px);
        font-size: 24px;
        opacity: 0.85;
        margin-left: 3px;
      }
    }
    .conter_bg {
      width: 1200px;
      height: 160px;
      margin: 0 auto;
      //   margin-top: 135px;
      position: relative;
    }
    .briefIntroduction {
      position: absolute;
      top: 55px;
      left: 22px;
      font-size: 16px;
      text-align: left;
    }
  }
}
</style>